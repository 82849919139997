import React, {Component} from "react";
import {connect} from "react-redux";
import styled, {css, keyframes} from "styled-components";
import { Link } from 'gatsby'
import { useMount, useSetState } from 'react-use';

import { Layout, Footer, MetaTags } from '../../components'

import {isClient, media} from "../../styles/utils";
import {container, bgIcon, bgImage, hoverState, button, padding} from "../../styles/global";
import {data} from "./data";

import logo from '../../assets/images/logo.png'
import genericBG from '../../assets/images/generic-bg.jpg'
import loader from '../../assets/images/loader.svg'

import { fetchSettings, updateSettings } from '../../store/settings'
import { getIntitalState } from "./utils";
import { inputStyle } from "../../components/Input/Input";
import { orange, darkGrey } from "../../styles/colors";

const RegisterThankyou = (props) => {
	if (!isClient()) return <></>

	const acf_json = JSON.parse(props.pageContext.acf_json)
	const landing_json = JSON.parse(props.pageContext.landing_json)

	return (
		<Layout>
			<MetaTags 
				title={`Adventures in the Field`}
			/>

			<Wrapper
				bgImage={genericBG}
			>
				<Container>
					<Logo 
						to={'/'}
					/>

					<Content>
						{acf_json?.thankyou_text && (
							<Description
								dangerouslySetInnerHTML={{
									__html: acf_json.thankyou_text
								}}
							/>
						)}

						<LoginLink to={'/?login=true'}>Go to login</LoginLink>

						{acf_json?.thankyou_pixel_code && (
							<Pixel
								dangerouslySetInnerHTML={{
									__html: acf_json.thankyou_pixel_code
								}}
							/>
						)}
					</Content>
				</Container>
			</Wrapper>

			<Footer
				copyrightOnly
			/>
		</Layout>
	)
}

const Pixel = styled.div`
	display: none;
`

const Wrapper = styled.div`
    ${bgImage}
    background-image: url(${props => props.bgImage});
    min-height: 100vh;
`

const Container = styled.div`
	${container};
	${padding};
    display: flex;
    flex-direction: column;
    align-items: center; 
`

const Logo = styled(Link)`
    position: absolute;
    top: 24px;
    left: 24px;
    ${bgImage}
    background-image: url(${logo});
    width: 165px;
    height: 134px;
`

const Content = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
    background-color: rgba(255, 255, 255, 0.76); 
    border-radius: 21.3px;
    padding: 23px;  
	margin: 64px 32px 120px;
    max-width: 580px;
	z-index: 3;
	gap: 16px;
	width: 100%;

	${media.phone`
		margin: 180px 0 120px;
		background: white;
	`}
`

const Description = styled.div`
	&, p {
		font-size: 16px;
		margin-bottom: 0;
		color: ${darkGrey};
	}

	a {
		color: #dfc0b0;
		border-bottom: 1px solid #dfc0b0;
		${hoverState}
	}
`

const LoginLink = styled(Link)`
	font-size: 16px;
    margin-bottom: 0;
	color: ${darkGrey};
`

export default RegisterThankyou
